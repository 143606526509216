import { Common } from '@/lib/strapi-types/Common';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export async function useCommon() {
  const { findOne } = useStrapi<Common['attributes']>();

  const { data: commonData } = await useAsyncData(BackEndpoints.COMMON, () =>
    findOne(BackEndpoints.COMMON, {
      populate: {
        socials: { fields: ['*'] },
        responsabilities: { fields: ['*'] },
        commitmentSection: {
          populate: {
            CTA: { fields: ['*'] },
            image: { fields: ['url', 'alternativeText'] },
            icon: { fields: ['*'] }
          }
        },
        ratingsSection: { fields: ['*'] },
        expertsSection: {
          populate: {
            experts: {
              populate: {
                image: { fields: ['url', 'alternativeText'] },
                countries: {
                  populate: { geographicZones: { fields: ['*'] } }
                },
                geographicZones: { fields: ['*'] }
              }
            }
          }
        },
        articlesSection: {
          populate: {
            articles: {
              sort: ['publishedAt:DESC'],
              populate: {
                image: { fields: ['url', 'alternativeText'] },
                tags: { fields: ['*'] },
                geographicZones: { fields: ['name', 'slug'] },
                countries: { fields: ['slug'] }
              }
            }
          }
        },
        newsletter: { populate: { image: { fields: ['url', 'alternativeText'] } } },
        eventsSection: {
          populate: {
            events: {
              populate: {
                image: { fields: ['url', 'alternativeText'] },
                tags: { fields: ['*'] },
                countries: {
                  populate: { geographicZones: { fields: ['name', 'slug'] } }
                },
                geographicZones: { fields: ['name', 'slug'] }
              }
            }
          }
        },
        headerBand: { fields: ['*'] },
        trendsSection: {
          fields: ['title', 'description'],
          populate: {
            countries: {
              fields: ['iso']
            }
          }
        }
      }
    })
  );

  return {
    socials: commonData.value?.data.attributes.socials,
    responsabilities: commonData.value?.data.attributes.responsabilities,
    commitmentSection: commonData.value?.data.attributes.commitmentSection,
    ratingsSection: commonData.value?.data.attributes.ratingsSection,
    expertsSection: commonData.value?.data.attributes.expertsSection,
    articlesSection: commonData.value?.data.attributes.articlesSection,
    eventsSection: commonData.value?.data.attributes.eventsSection,
    newsletter: commonData.value?.data.attributes.newsletter,
    headerBand: commonData.value?.data.attributes.headerBand,
    trendsSection: commonData.value?.data.attributes.trendsSection
  };
}
